<div [class.hidden]="$hiddenSidebar | async" class="sidebar-wrapper" *ngIf="exisToken">
  <div class="sidebar">
    <div class="sidebar-header">
      <button class="close-button" (click)="closeSidebar()">
        <i class="pi pi-times"></i>
      </button>
    </div>
    <div class="sidebar-content">
      <ul>
        <!-- ADMIN -->
    <li *ngIf="isAdmin"><a href="/construction" routerLinkActive="active-link">
      Sistema de fidelización </a> 
    </li>
    <li *ngIf="isAdmin"><a [routerLink]="['/manager/reservation-manager']" routerLinkActive="active-link">
      Gestión de Reservas </a>
    </li>
    <li *ngIf="isAdmin" ><a [routerLink]="['/manager/apartment-manager']" routerLinkActive="active-link">
      Gestión de Apartamentos </a>
    </li>
    <li *ngIf="isAdmin" ><a [routerLink]="['/manager/payment-manager']" routerLinkActive="active-link">
      Gestor de pagos </a>
    </li>
    <li *ngIf="isAdmin" ><a [routerLink]="['/manager/service-manager']" routerLinkActive="active-link">
      Gestor de servicios </a>
    </li>
    <li *ngIf="isAdmin"><a href="/construction" routerLinkActive="active-link">Gestor de paquetes</a></li>
    <li *ngIf="isAdmin"><a href="/construction" routerLinkActive="active-link">Gestor de promotores</a></li>
    <li *ngIf="isAdmin" ><a [routerLink]="['/manager/report-manager']" routerLinkActive="active-link">
      Gestor de Reportes </a>
    </li>

    <!-- RECEPTIONIST -->
    <li *ngIf="isReceptionist" ><a [routerLink]="['/manager/dashboard-manager']" routerLinkActive="active-link">
      Dashboard </a>
    </li>
    <li *ngIf="isReceptionist" ><a [routerLink]="['/manager/bookings-manager']" routerLinkActive="active-link">
      Reservas </a>
    </li>
      </ul>
    </div>
  </div>
</div>
